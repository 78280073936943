/*   kontak form */

#formInputs.hide {
	display: none;
}
.container ul{
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  margin-top: -20px;
}



.rezervace ul li{
  color: #868686;
  display: block;
  position: relative;
  float: left;
  width: 40%;
  height: 60px;
   -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);

}

.velikost {
  padding-top: 25px;
  padding-bottom: 0px;
  padding-left: 15px !important;
}

.rezervace ul li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

.rezervace ul li label{
  display: block;
  position: relative;

  font-size: 18px;
  padding: 25px 25px 25px 80px;
  margin: 10px auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;



  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}


.rezervace ul li label sup {
   vertical-align: super;
   font-size: 8px;
  }


.rezervace ul li:hover label{
  color: #000000;
}

.rezervace ul li .check{
  display: block;
  position: absolute;
  border: 5px solid #868686;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 30px;
  left: 20px;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
}

.rezervace ul li:hover .check {
  border: 5px solid #000000;
}

.rezervace ul li .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 11px;
  width: 11px;
  top: 2px;
  left: 2px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type=radio]:checked ~ .check {
  border: 5px solid #868686;
}

input[type=radio]:checked ~ .check::before{
  background: #000000;
}

input[type=radio]:checked ~ label{
  color: #000000;
}

.signature {
  margin: 10px auto;
  padding: 10px 0;
  width: 100%;
}

.signature p{
  text-align: center;
    font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #AAAAAA;
}

.signature .much-heart{
  display: inline-block;
  position: relative;
  margin: 0 4px;
  height: 10px;
  width: 10px;
  background: #AC1D3F;
  border-radius: 4px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.signature .much-heart::before, 
.signature .much-heart::after {
    display: block;
  content: '';
  position: absolute;
  margin: auto;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #AC1D3F;
  top: -4px;
}

.signature .much-heart::after {
  bottom: 0;
  top: auto;
  left: -4px;
}

.signature a {
  color: #AAAAAA;
  text-decoration: none;
  font-weight: bold;
}


/* Styles for alert... 
by the way it is so weird when you look at your code a couple of years after you wrote it XD */

.alert {
  box-sizing: border-box;
  background-color: #0DFF92;
  width: 100%;
  position: relative; 
  top: 0;
  left: 0;
  z-index: 300;
  padding: 20px 40px;
  color: #333;
}

.alert h2 {
  font-size: 22px;
  color: #232323;
  margin-top: 0;
}

.alert p {
  line-height: 1.6em;
  font-size:18px;
}

.alert a {
  color: #232323;
  font-weight: bold;
}


#sendOk {

          font-family: 'Poppins', sans-serif;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
       
        color: #000000;
     
        opacity: 0;
       -webkit-transform: translateY(25px);
    -ms-transform: translateY(25px);
    transform: translateY(25px);
       
}

.button {
    display: inline-block;
font-family: 'Poppins', sans-serif;
        
        font-weight: 400;
        font-size: 20px;
        text-decoration: none;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 50px;
        padding-right: 50px;
 
        -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
  background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);

        -webkit-box-shadow: 9px 12px 70px -10px rgba(0,0,0,0.20);
    -moz-box-shadow: 9px 12px 70px -10px rgba(0,0,0,0.20);
    box-shadow: 9px 12px 70px -10px rgba(0,0,0,0.20);  

position: absolute;
left: 50%;
margin-left: -100px;
}


.buttonHolder.sendBut {
      -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); 

}

.button.buttonSend {
margin-top: 0;


}

.button:hover {
cursor: pointer;
        -webkit-box-shadow: 9px 12px 70px -10px rgba(0,0,0,0.6);
    -moz-box-shadow: 9px 12px 70px -10px rgba(0,0,0,0.6);
    box-shadow: 9px 12px 70px -10px rgba(0,0,0,0.6);   
           -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
}


#sendOk.show {
 -webkit-transition: opacity 0.3s cubic-bezier(.7,0,.3,1);
    transition: opacity 0.3s cubic-bezier(.7,0,.3,1);
        opacity: 1;
    }


.required.show {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
}


.required {
    opacity: 0;
    text-align: center;
    display: block;
    padding-top: 15px;
    padding-bottom: 55px;
    clear: both;
    font-weight: 600;
    font-size: 13px;
    color: black;
    position: relative;
  font-family: 'Poppins', sans-serif;
    -webkit-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
}



.input {
    position: relative;
    z-index: 1;
    display: inline-block;
    max-width: 100%%;
    width: calc(100%);
    vertical-align: top;
    float: left;
}

.formHolder {
       text-align: left;
    width: 1000px;
    margin-bottom: 45px;
    padding-bottom: 0px;
    padding-top: 20px;
}


.input__field {
    position: relative;
    display: block;
    float: right;
    padding: 0.8em;
    width: 50%;
    border: none;
    border-radius: 0;
    background: #1a1e23;
    color: #000;
    font-weight: bold;
  font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    -webkit-appearance: none;
    /* for box shadows to show on iOS */
}
.input__field:focus {
    outline: none;
}
.input__label {
    display: inline-block;
    float: right;
    padding: 0 1em;
    width: 50%;
    color: #6a7989;
    font-weight: 300;
    font-size: 70.25%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.input__label-content {
    position: relative;
    display: block;
    padding: 1.6em 0;
    width: 100%;
}
.input__label-content.error {
    color: #d41217 !important;
}
.graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
}
.icon {
    color: #ddd;
    font-size: 150%;
}
/* Individual styles */
/* Kaede */

.input--kaede {
    display: block;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    background: rgba(255,255,255,0.2);
    border-bottom: 1px solid #868686; 
   
}
.input__field--kaede {
    position: absolute;
    top: 0;
    right: 100%;
    width: 60%;
    height: 100%;
  background-color: #ffffff;

    color: #000;
   
    color: #281d07;
    padding-left: 25px;
  font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500; 
    font-size: 20px;
    letter-spacing: 1;
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.input__label--kaede {
    z-index: 10;
    display: block;
    width: 100%;
    height: 100%;
    text-align: left;
    cursor: text;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.input__label-content--kaede {
    padding: 10px 0;
  font-family: 'Poppins', sans-serif;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}
.input__field--kaede:focus,
.input--filled .input__field--kaede {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition-delay: 0.06s;
    transition-delay: 0.06s;
}
.input__field--kaede:focus + .input__label--kaede,
.input--filled .input__label--kaede {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    pointer-events: none;
     opacity: 0.3;
}
@media screen and (max-width: 34em) {
    .input__field--kaede:focus + .input__label--kaede,
    .input--filled .input__label--kaede {
        -webkit-transform: translate3d(65%, 0, 0) scale3d(0.65, 0.65, 1);
        transform: translate3d(65%, 0, 0) scale3d(0.65, 0.65, 1);
        pointer-events: none;

    }
}

@media (min-width: 720px) and (max-width: 1200px) {


    .formHolder {
        width: calc(100% - 160px);
}

}

@media (min-width: 320px) and (max-width: 719px) {
    .formHolder {
        padding-left: 10px;
        padding-right: 10px;
        width: calc(100% );
}

.input__label-content--kaede {

    font-size: 14px;
  
}

.input--kaede {

    padding-left: 15px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.required {
   
    padding-top: 15px;
    padding-bottom: 15px;

}

    }